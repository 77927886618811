<template>
	<div class="page-clerkminutes-unlock">
		<div class="card">
			<div class="card-body">
				<div class="mt-5 mx-3">
					<h2 class="display-3 text-center text-balance mb-5">
						Create Meeting Minutes, in Minutes!
					</h2>

					<p v-if="j.used_clerkminutes" class="lead text-center mb-5">
						<a href="https://clerkminutes.com/" target="clerkminutes">Learn more</a> about how ClerkMinutes
						works.
					</p>

					<div v-else class="row align-items-center mb-6">
						<div class="col-lg-6">
							<p class="lead">
								Ditch manual transcribing. Embrace automation. Boost productivity by 10x in just 10
								minutes.
							</p>
							<p class="lead">
								<a href="https://clerkminutes.com/" target="clerkminutes">Learn more</a> about how
								ClerkMinutes works.
							</p>
						</div>
						<div class="col">
							<img
								src="https://edge.heygov.com/illustrations/clerkminutes.webp"
								alt="ClerkMinutes"
								class="img-fluid"
							/>
						</div>
					</div>

					<div v-if="j.used_clerkminutes">
						<stripe-pricing-table
							v-if="stripeCustomerReference && j.testmode"
							pricing-table-id="prctbl_1P0mJiLn2cdp6hSMGrTUXQpv"
							:publishable-key="stripePublishableTestKey"
							:customer-session-client-secret="stripeCustomerReference"
						>
						</stripe-pricing-table>
						<stripe-pricing-table
							v-else-if="stripeCustomerReference"
							pricing-table-id="prctbl_1OeHfILn2cdp6hSMsBq10PiD"
							:publishable-key="stripePublishableKey"
							:customer-session-client-secret="stripeCustomerReference"
						>
						</stripe-pricing-table>
					</div>

					<form v-else @submit.prevent="startClerkMinutes" class="text-center mb-5">
						<p class="mb-1">
							Start using ClerkMinutes for free for 30 days, <strong>no credit card required.</strong>
						</p>
						<p class="mb-3">After 30 days, you can choose a plan starting from $99</p>
						<!-- <p class="text-neutral-400">
							<span class="text-primary-300 cursor-pointer" @click="showReferralCode = true"
								>Add a referral code</span
							>
							to extend the free trial to 30 days
						</p> -->

						<div v-if="showReferralCode" class="row justify-content-center mb-3">
							<div class="col-lg-4 col-md-6">
								<div class="form-floating">
									<input
										type="text"
										class="form-control"
										id="referral-code"
										v-model="referralCode"
										placeholder="Referral code"
										minlength="4"
									/>
									<label for="referral-code">Referral code</label>
								</div>
							</div>
						</div>

						<div v-if="errorMessage" class="row justify-content-center mb-3">
							<div class="col-lg-4 col-md-6">
								<div class="alert alert-danger">
									{{ errorMessage }}
								</div>
							</div>
						</div>

						<button class="btn btn-primary px-5" :disabled="loading">
							Unlock ClerkMinutes
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.hey-reserve-upgrade {
	border: 1px solid #7879f1 !important;
}
.hey-licence-upgrade {
	border: solid 1px #22a6ff !important;
}
.heygov-pay-upgrade {
	border: 1px solid #02cea7 !important;
}
.hey-311-upgrade {
	border: 1px solid #ff5d46 !important;
}
</style>

<script>
import { mapState } from 'vuex'

import { hgApi } from '@/api.js'

export default {
	data() {
		return {
			showReferralCode: this.$route.query.referral_code ? true : false,
			referralCode: this.$route.query.referral_code || '',
			loading: false,
			errorMessage: '',
			stripeCustomerReference: null,
		}
	},
	computed: {
		...mapState(['j', 'stripePublishableKey', 'stripePublishableTestKey']),
	},
	created() {
		if (this.j.used_clerkminutes) {
			hgApi(`/${this.j.slug}/billing/customer-session`, { method: 'POST' })
				.then(response => response.json())
				.then(data => {
					this.stripeCustomerReference = data
				})
		}
	},
	mounted() {
		let stripePricingScript = document.createElement('script')
		stripePricingScript.setAttribute('src', 'https://js.stripe.com/v3/pricing-table.js')
		document.head.appendChild(stripePricingScript)
	},
	methods: {
		async startClerkMinutes() {
			this.loading = true
			this.errorMessage = ''

			const response = await hgApi(`/${this.j.slug}/meetings/start-clerkminutes-subscription`, {
				method: 'POST',
				body: {
					referral_code: this.referralCode,
				},
			})

			this.loading = false

			if (response.ok) {
				this.j.features.push('clerkminutes')
				this.$router.push(`/${this.j.slug}/meetings?unlocked=1`)
			} else {
				const error = await response.text()

				this.errorMessage = error || `Error starting ClerkMinutes (${response.statusText})`
			}
		},
	},
}
</script>
